import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from '../components/Layout'
import BlogRollHomepage from '../components/BlogRollHomepage'
import ArtRollHomepage from '../components/ArtRollHomepage'

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(/img/sam-desert.jpg)`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                '#FADE29 0.5rem 0px 0px, #FADE29 -0.5rem 0px 0px',
              backgroundColor: '#FADE29',
              color: '#28282A',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {title}
          </h1>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              boxShadow:
                '#FADE29 0.5rem 0px 0px, #FADE29 -0.5rem 0px 0px',
              backgroundColor: '#FADE29',
              color: '#28282A',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {subheading}
          </h3>
        </div>
      </div>
      <div className="column is-10 container">
        <div className="content">
          <div className="column is-12">
            <h3 className="has-text-weight-semibold is-size-2" style={{paddingLeft: "0.5rem"}}>
              Latest stories
            </h3>
            <BlogRollHomepage />
            <div className="column is-12 has-text-centered">
              <Link className="btn" to="/blog">
                Read more
              </Link>
            </div>
          </div>
          <div className="column is-12">
            <h3 className="has-text-weight-semibold is-size-2" style={{paddingLeft: "0.5rem"}}>
              Latest art projects
            </h3>
            <ArtRollHomepage />
            <div className="column is-12 has-text-centered">
              <Link className="btn" to="/art">
                See more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
      }
    }
  }
`;
